/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, {useState,useEffect} from 'react';
import {
  styled,
  TextField,
  Button,
  Paper,
  Grid,
  Divider,
  Box,
  Typography,
  InputAdornment,
  IconButton
} from '@mui/material';
import * as yup from 'yup';
import Iconify from '../../iconify';
import Modal from '../../modal/Modal';
import { ItemCodeSearchForm, ItemNameSearchForm, LineVendorSearchForm } from '../../search';
import { useAuthSap } from '../../../hooks';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function PRDocumentLineForm(props){
  const { business, docLine, addDoc, msgError } = props;
  const { collectionWH, collectionCF, collectionCC, TaxesQuery, Warehouses, CashFlow, CenterCost } = useAuthSap();
  const [ taxes, setTaxes] = useState([]);
  const [loadWarehouses, setLoadWarehouses] = useState(true);
  const [loadCashFlow, setLoadCashFlow] = useState(true);
  const [loadCenterCost, setLoadCenterCost] = useState(true);
  const [ formData, setFormData ] = useState({
    ItemCode: docLine != null ? docLine?.ItemCode:'',
    ItemDescription: docLine != null ? docLine?.ItemDescription:'',
    LineVendor: docLine != null ? docLine?.LineVendor:'',
    nameVendor: docLine != null ? docLine?.nameVendor:'',
    U_Comentarios: docLine != null ? docLine?.U_Comentarios:'',
    MeasureUnit: docLine != null ? docLine?.MeasureUnit:'',
    UoMCode: docLine != null ? docLine?.UoMCode:'',
    WarehouseCode: docLine != null ? docLine?.WarehouseCode:0,
    Quantity: docLine != null ? docLine?.Quantity:0,
    UnitPrice: docLine != null ? docLine?.UnitPrice:0,
    CostingCode: docLine != null ? docLine?.CostingCode:0,
    Currency: docLine != null ? docLine?.Currency:'MXP',
    TaxCode: docLine != null ? docLine?.TaxCode:0
  });
  const [ typeModal, setTypeModal ] = useState('ItemCode');
  const [ titleModal, setTitleModal ] = useState('código de artículo');
  const [ open, setOpen ] = useState(!docLine);
  const [ errors, setErrors ] = useState([]);

  const PurchaseLineSchema = yup.object().shape({
    ItemCode: yup
      .string()
      .min(1, 'Mínimo 1 carácteres')
      .required('No.Articulo es requerido'),
    ItemDescription: yup
      .string()
      .min(1, 'Mínimo 1 carácteres')
      .required('Descripción es requerido'),
    // LineVendor: yup
    //   .string()
    //   .min(1, 'Mínimo 1 carácteres')
    //   .required('No.Proveedor es requerido'),
    // MeasureUnit: yup
    //   .string()
    //   .min(1, 'Mínimo 1 carácteres'),
      // .required('Unidad de medida es requerido'),
    // WarehouseCode: yup
    //   .string()
    //   .oneOf(collectionWH.map(item => item.WarehouseCode), 'Selecciona un almacen'),
      // .required('El almacen es requerido'),
    // CostingCode: yup
    //   .string()
    //   .oneOf(collectionCC.map(item => item.center_cost.code), 'Selecciona un centro de costo'),
      // .required('El centro de costo es requerido'),
    // TaxCode: yup
    //   .string()
    //   .oneOf(taxes.map(item => item.Code), 'Selecciona un impuesto'),
      // .required('El impuesto es requerido'),
    Quantity: yup
      .number()
      .min(1, 'Cantidad debe de ser mayor a 0'),
    // UnitPrice: yup
    //   .number()
    //   .min(0.01, 'Precio unidad debe de ser mayor a 0')
  });

  const fetchData = async () => {
    try {
      await CashFlow();
      setLoadCashFlow(false);
      await Warehouses();
      setLoadWarehouses(false);
      await CenterCost();
      setLoadCenterCost(false);
      const { result } = await TaxesQuery();
      setTaxes(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    localStorage.setItem('business',business);
    fetchData();
  }, []);

  const handleOpenModal = (type) => {
    if (type === 'ItemCode') {
      setTitleModal('código de artículo');
    } else if (type === 'ItemDescription') {
      setTitleModal('descripción de artículo');
    } else if(type === 'LineVendor') {
      setTitleModal('nombre de proveedor');
    }
    setTypeModal(type);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleSearch = (search) => {
    if(typeModal === 'LineVendor') {
      setFormData((prevState) => ({ ...prevState, LineVendor: search.CardCode }));
      setFormData((prevState) => ({ ...prevState, nameVendor: search.CardName }));
      setErrors({ ...errors, LineVendor: "" });
    } else {
      setFormData((prevState) => ({ ...prevState, ItemCode: search.ItemCode != null ? search.ItemCode:'' }));
      setFormData((prevState) => ({ ...prevState, ItemDescription: search.ItemName != null ? search.ItemName:'' }));
      setFormData((prevState) => ({ ...prevState, MeasureUnit: search.PurchaseUnit != null ? search.PurchaseUnit:'' }));
      setFormData((prevState) => ({ ...prevState, UoMCode: search.PurchaseUnit != null ? search.PurchaseUnit:'' }));
      setFormData((prevState) => ({ ...prevState, WarehouseCode: search.DefaultWarehouse != null ? search.DefaultWarehouse:0 }));
      setErrors({ ...errors, 
        ItemCode: "", 
        ItemDescription: "" ,
        MeasureUnit: "" ,
        WarehouseCode: "" ,
      });

    };
    setOpen(false);
  };
  const inputChange = async (event) => {
    const { name, value } = event.target;
    let processedValue = value;
    if (name === 'LineVendor'){
      processedValue = value;
    } else if (name === 'U_Comentarios'){
      processedValue = value;
    } else if (name === 'MeasureUnit'){
      processedValue = value.toUpperCase();
      setFormData((prevState) => ({...prevState, 'UoMCode': processedValue}));
    } else if (name === 'WarehouseCode'){
      processedValue = value;
    } else if (name === 'Quantity'){
      processedValue = value;
    } else if (name === 'UnitPrice'){
      processedValue = value;
    } else if (name === 'CostingCode'){
      processedValue = value;
      setFormData((prevState) => ({ ...prevState, CostingCode: processedValue }));
    } else if (name === 'DiscountPercent'){
      processedValue = value;
    } else if (name === 'TaxCode'){
      processedValue = value;
    }

    setFormData((prevState) => ({...prevState, [name]: processedValue}));
    await validateField(name, processedValue);
    // await PurchaseLineSchema.validateAt(name, { [name]: processedValue })
    // .then(() => {
    //   setErrors({ ...errors, [name]: "" });
    // })
    // .catch((err) => {
    //   setErrors({ ...errors, [name]: err.errors[0] });
    // });
  };
  const validateField = async (name, value) => {
    try {
      await PurchaseLineSchema.validateAt(name, { [name]: value });
      setErrors({ ...errors, [name]: "" });
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setErrors({ ...errors, [name]: err.errors[0] });
      }
    }
  };
  const onAdd = async () => {
    try {
      await PurchaseLineSchema.validate(formData, { abortEarly: false });
      addDoc(formData);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setErrors(err.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {}));
      }
    }
  };

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3}>
            <Item>
              <TextField 
                disabled 
                id="ItemCode"
                name="ItemCode"
                label="No.Articulo" 
                type="text" 
                size="small" 
                variant="outlined" 
                fullWidth 
                value={formData.ItemCode}
                error={Boolean(errors.ItemCode)}
                helperText={errors.ItemCode}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <IconButton
                      aria-label="ItemCode"
                      onClick={() => handleOpenModal('ItemCode')}
                      // onMouseDown={handleMouseDownPassword}
                      edge="start"
                    >
                      <Iconify icon="ic:outline-settings" />
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Item>
              <TextField 
                disabled 
                id="ItemDescription"
                name="ItemDescription"
                label="Descripción" 
                type="text" 
                size="small" 
                variant="outlined" 
                fullWidth 
                value={formData.ItemDescription}
                error={Boolean(errors.ItemDescription)}
                helperText={errors.ItemDescription}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <IconButton
                      aria-label="ItemDescription"
                      onClick={() => handleOpenModal('ItemDescription')}
                      // onMouseDown={handleMouseDownPassword}
                      edge="start"
                    >
                      <Iconify icon="ic:outline-settings"/>
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Item>
              <TextField 
                disabled 
                id="LineVendor"
                name="LineVendor"
                label="Proveedor" 
                type="text" 
                size="small" 
                variant="outlined" 
                fullWidth 
                onChange={inputChange}
                value={formData.LineVendor}
                error={Boolean(errors.LineVendor)}
                helperText={errors.LineVendor}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <IconButton
                      aria-label="LineVendor"
                      onClick={() => handleOpenModal('LineVendor')}
                      // onMouseDown={handleMouseDownPassword}
                      edge="start"
                    >
                      <Iconify icon="ic:outline-settings" />
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Item>
              <TextField 
                autoFocus 
                id="U_Comentarios"
                name="U_Comentarios"
                label="Comentario" 
                type="text" 
                size="small" 
                variant="outlined" 
                fullWidth 
                onChange={inputChange}
                value={formData.U_Comentarios}
                multiline
                rows={4}
                autoComplete="off"
              />
            </Item>
          </Grid>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Divider sx={{ my: 2, borderBottom: '1px dashed #EBEDF3' }}/>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Item>
              <TextField 
                autoFocus 
                id="MeasureUnit"
                name="MeasureUnit"
                label="Unidad Medida" 
                type="text" 
                size="small" 
                variant="outlined" 
                fullWidth 
                onChange={inputChange}
                value={formData.MeasureUnit}
                error={Boolean(errors.MeasureUnit)}
                helperText={errors.MeasureUnit}
                autoComplete="off"
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Item>
              <TextField 
                select 
                SelectProps={{ native: true }} 
                id="WarehouseCode"
                name="WarehouseCode"
                label="Almacen"
                onChange={inputChange}
                value={formData.WarehouseCode} 
                error={Boolean(errors.WarehouseCode)}
                helperText={errors.WarehouseCode}
                fullWidth 
                size="small">
                <option value={0}>Selecciona un almacen</option>
                {collectionWH.map((option) => (
                  <option key={option.WarehouseCode} value={option.WarehouseCode}>{option.WarehouseCode} - {option.WarehouseName}</option>
                ))}
              </TextField>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Item>
              <TextField 
                type="number"
                onFocus={(e) => e.target.select()} 
                id="Quantity"
                name="Quantity"
                label="Cantidad" 
                size="small" 
                variant="outlined" 
                fullWidth 
                onChange={inputChange}
                value={formData.Quantity}
                error={Boolean(errors.Quantity)}
                helperText={errors.Quantity}
                autoComplete="off"
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Item>
              <TextField 
                type="number"
                onFocus={(e) => e.target.select()}
                id="UnitPrice"
                name="UnitPrice"
                label="Precio" 
                size="small" 
                variant="outlined" 
                fullWidth 
                onChange={inputChange}
                value={formData.UnitPrice}
                error={Boolean(errors.UnitPrice)}
                helperText={errors.UnitPrice}
                autoComplete="off"
              />
            </Item>
          </Grid>
          {taxes && (
            <Grid item xs={12} sm={6} md={6}>
              <Item>
                <TextField
                  disabled={loadCenterCost}
                  select 
                  SelectProps={{ native: true }} 
                  id="TaxCode"
                  name="TaxCode"
                  label="Impuestos"
                  onChange={inputChange}
                  value={formData.TaxCode} 
                  error={Boolean(errors.TaxCode)}
                  helperText={errors.TaxCode}
                  fullWidth 
                  size="small">
                  <option value={0}>Selecciona un impuesto</option>
                  {taxes.map((option, index) => (
                    <option key={index} value={option.Code}>{option.Code} - {option.Name}</option>
                  ))}
                </TextField>
              </Item>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={6}>
            <Item>
              <TextField
                disabled={loadCenterCost}
                select 
                SelectProps={{ native: true }} 
                id="CostingCode"
                name="CostingCode"
                label="Centro de Costo"
                onChange={inputChange}
                value={formData.CostingCode} 
                error={Boolean(errors.CostingCode)}
                helperText={errors.CostingCode}
                fullWidth 
                size="small">
                <option value={0}>Selecciona un centro de costo</option>
                {collectionCC.map((option) => (
                  <option key={option.id} value={option.center_cost.code}>{option.center_cost.code} - {option.center_cost.description}</option>
                ))}
              </TextField>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Item>
              <TextField
                select 
                SelectProps={{ native: true }} 
                id="Currency"
                name="Currency"
                label="Tipo Moneda"
                onChange={inputChange}
                value={formData.Currency} 
                error={Boolean(errors.Currency)}
                helperText={errors.Currency}
                fullWidth 
                size="small">
                <option key={0} value={' '}>Selecciona la moneda</option>
                <option key={1} value={'$'}>$</option>
                {/* <option key={1} value={'MXP'}>MXP</option> */}
                <option key={2} value={'USD'}>USD</option>
                <option key={3} value={'EUR'}>EUR</option>
                <option key={4} value={'GBP'}>LIBRA</option>
                {/* {collectionCC.map((option) => (
                  <option key={option.centercost.id} value={option.centercost.code}>{option.centercost.code} - {option.centercost.description}</option>
                ))} */}
              </TextField>
            </Item>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2, borderBottom: '1px dashed #EBEDF3' }}/>
        <Box sx={{ alignItems: 'center', display: 'flex'}}>
          {msgError != null ? (
            <Typography color="error.main" variant="body2">
              {msgError}
            </Typography>
          ):null}
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" onClick={onAdd} startIcon={<Iconify icon={docLine == null ? 'eva:plus-fill':'radix-icons:update'} />}>
            {docLine == null ? 'Agregar':'Actualizar'}
          </Button> 
        </Box>  
      </Box>
      <Modal title={`Búsqueda por ${titleModal}`} maxWidth={'xs'} open={open} onClose={handleCloseModal}>
        {
          typeModal === 'ItemCode' && 
          (
            <ItemCodeSearchForm send={handleSearch}/>
          )
        }
        {
          typeModal === 'ItemDescription' && 
          (
            <ItemNameSearchForm send={handleSearch}/>
          )
        }
        {
          typeModal === 'LineVendor' && 
          (
            <LineVendorSearchForm send={handleSearch}/>
          )
        }
      </Modal>
    </>
  )
}
